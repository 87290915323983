import { Icon } from "leaflet";
import React from "react";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";
import marker from "../assets/imgs/marker.png";
function Map({ data, height }) {
  const position = [25.278229, 48.488376];
  const customMarkerIcon = new Icon({
    iconUrl: marker,
    iconSize: [25, 35], // size of the icon
    iconAnchor: [22, 94], // point of the icon which will correspond to marker's location
    popupAnchor: [-3, -76], // point from which the popup should open relative to the iconAnchor
  });
  return (
    <>
      <style>{`
    .map {
      height: ${height}px;
    }
    
    @media only screen and (max-width: 1023px) {
      .map {
        height: 300px;
      }
    }
    `}</style>
      <div className={`map`} style={{ width: "100%" }}>
        <MapContainer
          center={position}
          zoom={5}
          scrollWheelZoom={false}
          style={{ minHeight: "100%", zIndex: "0" }}
        >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          {data.map((el, index) => (
            <Marker
              key={index}
              icon={customMarkerIcon}
              position={[el.lat, el.long]}
            >
              <Popup>{el.info}</Popup>
            </Marker>
          ))}
        </MapContainer>
      </div>
    </>
  );
}

export default Map;
