import React, { useRef } from "react";
import logo from "../assets/imgs/logoGroup.png";
import target from "../assets/imgs/target.png";
import Goal from "./Goal";
import goal1 from "../assets/imgs/goal1.png";
import goal2 from "../assets/imgs/goal2.png";
import goal3 from "../assets/imgs/goal3.png";
import goal4 from "../assets/imgs/goal4.png";
import goal5 from "../assets/imgs/goal5.png";
import goal6 from "../assets/imgs/goal6.png";
import goal7 from "../assets/imgs/goal7.png";
import goal8 from "../assets/imgs/goal8.png";
import { motion } from "framer-motion";
import { FormattedMessage } from "react-intl";

function Goals({ data }) {
  const scrollRef = useRef(null);

  return (
    <motion.div
      ref={scrollRef}
      initial={{ opacity: 0, x: "-100%" }}
      whileInView={{ opacity: 1, x: 0 }}
      transition={{ duration: 0.8 }}
      viewport={{ once: true }}
      className="flex justify-center items-center my-32 md:my-44 z-30"
    >
      <div className="relative w-[72vw] h-[72vw] md:w-[62vw] md:h-[62vw] lg:w-[37vw] lg:h-[37vw] flex justify-center items-center rounded-full">
        <Goal
          ref={scrollRef}
          title={data.goals_1.title}
          desc={data.goals_1.body}
          img={goal1}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_2.title}
          desc={data.goals_2.body}
          img={goal2}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_3.title}
          desc={data.goals_3.body}
          img={goal3}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_4.title}
          desc={data.goals_4.body}
          img={goal4}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_5.title}
          desc={data.goals_5.body}
          img={goal5}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_6.title}
          desc={data.goals_6.body}
          img={goal6}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_7.title}
          desc={data.goals_7.body}
          img={goal7}
        />
        <Goal
          ref={scrollRef}
          title={data.goals_8.title}
          desc={data.goals_8.body}
          img={goal8}
        />
        <div className="w-1/2 absolute top-1/2 -translate-y-1/2 left-1/2 -translate-x-1/2 flex flex-col">
          <img src={target} alt="" className="" />
          <h3 className="absolute bottom-0 left-1/2 -translate-x-1/2 text-sm md:text-lg lg:text-xl xl:text-2xl border-b-4 border-b-main-color">
            <FormattedMessage id="goals" />
          </h3>
        </div>
        <img src={logo} className="w-full h-full" alt="" />
      </div>
    </motion.div>
  );
}

export default Goals;
