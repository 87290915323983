import React, { forwardRef } from "react";
import { motion } from "framer-motion";

const Goal = forwardRef(({ title, img, desc }, ref) => {
  return (
    <motion.div
      className="child z-0 group relative flex flex-col justify-center items-center gap-3 w-[37%] md:w-[37%] h-[37%] md:h-[37%] p-3 border-2 bg-white border-main-color rounded-full cursor-pointer hover:bg-main-color hover:text-white"
      initial={{ opacity: 0 }}
      whileInView={{ opacity: 1 }}
      transition={{ duration: 0.8, delay: 1 }}
      viewport={{ once: true, root: ref.current }}
    >
      <div className="group-hover:hidden h-[32%] w-[32%] absolute top-0 left-1/2 -translate-x-1/2 -translate-y-1/2 md:translate-x-0 md:translate-y-0  md:static flex justify-center items-center bg-main-color rounded-full">
        <img className="w-[60%] " src={img} alt={""} />
      </div>
      <h3 className="group-hover:hidden text-center text-xs sm:text-sm">
        {title}
      </h3>
      <p className="hidden group-hover:block text-center text-xs">{desc} </p>
    </motion.div>
  );
});

export default Goal;
