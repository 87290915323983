import React from "react";
import SectionTitle from "./SectionTitle";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import brand1 from "../assets/imgs/brand1.png";
import brand2 from "../assets/imgs/brand2.png";
import brand3 from "../assets/imgs/brand3.png";
import brand4 from "../assets/imgs/brand4.png";
import brand5 from "../assets/imgs/brand5.png";
import brand6 from "../assets/imgs/brand6.png";
import { PiNumberSixThin } from "react-icons/pi";
import { FormattedMessage } from "react-intl";
function Brands({ data }) {
  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    // rows: 2,
    autoplay: true,
    pauseOnHover: false,
    pauseOnFocus: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          arrows: false,
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          arrows: false,
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
          rows: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          rows: 1,
        },
      },
    ],
  };
  return (
    <>
      <style>{`
		.brands .slick-dots .slick-active div{
				background:#09585A
			}
      .brands .slick-dots{
			bottom: -34px;
		}

// 	[dir='rtl'] .brands .slick-next {
// 			left: 60px;
// 	}
// 	[dir='rtl'] .brands .slick-prev {
//     right: 60px;
// 		z-index:20;
// }

.brands .slick-prev:before, .brands .slick-next:before{
	font-size: 35px !important;
	color: white !important;
}
		
		`}</style>
      <div id="brands" className="brands my-32 mt-20">
        <SectionTitle title={<FormattedMessage id="brands" />} />
        <div className="bg-[rgba(9,89,90,0.10)] mx-auto my-10">
          <div className="container mx-auto py-10">
            {data.map((el, index) => {
              return (
                <div className="slider-container" key={index}>
                  <Slider
                    prevArrow={
                      <div className="flex justify-center items-center w-20 h-20 bg-red-400">
                        <PiNumberSixThin />
                      </div>
                    }
                    {...settings}
                    rtl={index == 1}
                  >
                    {el.map((e, index) => {
                      return (
                        <div key={index}>
                          <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                            <img
                              src={brand6}
                              alt=""
                              className="w-[80%] h-[110px]"
                            />
                          </div>
                        </div>
                      );
                    })}
                  </Slider>
                </div>
              );
            })}
            {/* <div className="slider-container">
              <Slider
                prevArrow={
                  <div className="flex justify-center items-center w-20 h-20 bg-red-400">
                    <PiNumberSixThin />
                  </div>
                }
                {...settings}
                rtl
              >
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand1} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand2} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand3} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand4} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand5} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
                <div>
                  <div className="flex justify-center items-center w-[95%] my-5 mx-auto">
                    <img src={brand6} alt="" className="w-[80%] h-[110px]" />
                  </div>
                </div>
              </Slider>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default Brands;
