import React from "react";
import SectionTitle from "./SectionTitle";
import { useLanguage } from "../context/langContext";
import { motion } from "framer-motion";

function AboutSide({ reverse, img, title, description }) {
  const { language } = useLanguage();
  return (
    <div
      className={`w-full flex -z-10 ${
        reverse ? "justify-end sm:ps-5" : "justify-start sm:pe-5"
      } items-center`}
    >
      <div
        className={`relative w-full xl:w-2/3  flex flex-col justify-start ${
          reverse
            ? " rounded-s-[50px] sm:ps-32 items-start"
            : " rounded-e-[50px] sm:pe-32 items-end"
        } `}
      >
        <motion.h3
          initial={{ opacity: 0, y: -30 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ type: "spring", duration: 0.8, delay: 0.7 }}
          viewport={{ once: true }}
          className={`${
            reverse ? "ms-5 sm:ms-24" : "me-5 sm:me-24"
          } pb-1 border-b-4 border-b-main-color md:text-lg xl:text-2xl`}
        >
          {title}
        </motion.h3>
        <div
          className={`relative sm:px-10 py-7 flex flex-col justify-center bg-[#D4D4D459] ${
            reverse
              ? "md:items-start sm:rounded-s-full"
              : "md:items-end sm:rounded-e-full"
          }`}
        >
          <img
            className={`absolute ${
              reverse
                ? `start-0 ${
                    language == "en"
                      ? "translate-x-1/3 sm:-translate-x-1/2"
                      : "-translate-x-1/3 sm:translate-x-1/2"
                  } `
                : `end-0 ${
                    language == "en"
                      ? "-translate-x-1/3 sm:translate-x-1/2"
                      : "translate-x-1/3 sm:-translate-x-1/2"
                  }`
            } w-16 h-16 sm:w-32 sm:h-28 md:w-40 md:h-36`}
            src={img}
            alt=""
          />
          <motion.p
            initial={{ opacity: 0, y: -30 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ type: "spring", duration: 0.8, delay: 1 }}
            viewport={{ once: true }}
            className={`font-light w-full xl:w-2/3 text-xs md:text-base ${
              reverse ? "ps-24 pe-3 md:pe-0" : "pe-24 ps-3 md:ps-0"
            } mt-3`}
          >
            {description}
          </motion.p>
        </div>
      </div>
    </div>
  );
}

export default AboutSide;
